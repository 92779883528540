import { Component, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';

// Services
import { ConfigService } from 'src/app/services/config.service';
import { ApiService } from '../../services/api.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'my-loader',
  templateUrl: './loader.component.html',
})

export class LoaderComponent implements AfterViewInit, OnInit, OnDestroy {
  // Public Var
  public nativeEl: any;
  public loading: boolean = true;
  public homePageAssets: any = {};

  // Private Var
  private loaderEmitter: any = null;

  constructor(
    private el: ElementRef,
    public _dataService: DataService,
    private _configService: ConfigService
  ) {
    this.nativeEl = this.el.nativeElement;
  }

  ngOnInit() {
    this.homePageAssets = this._dataService.getAssets('homePage');
    this.loaderEmitter = this._configService.subscriberLoaderEmitter.subscribe((data) => {
      if (data === true) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.loaderEmitter.unsubscribe();
  }

  ngAfterViewInit() { }
}
