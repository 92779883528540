
    <app-main-header></app-main-header>

    <router-outlet></router-outlet>
    <!-- <my-loader></my-loader> -->
    <!-- <div *ngIf="!loading">
        <div class="loader" style="display: none;">
            <div class="loading-screen">
                <div class="spinner">
                    <img [src]="'assets/images/spinner.svg'" alt="Spinner" />
                </div>
                <img src="assets/pwa/spielbank/SB_Logo.svg" alt="Loading">
            </div>
        </div>
    </div> -->

    <ng-container>
        <app-main-footer></app-main-footer>
    </ng-container>


    <ng-container *ngIf="isMobile">
        <app-nav-bottom></app-nav-bottom>
    </ng-container>
