import { Injectable, TransferState, makeStateKey, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import 'rxjs/add/observable/of';
import { isPlatformServer } from '@angular/common';
import { catchError, tap } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { DataService } from './data.service';
import { AppService } from './app.service';
import { LanguageService } from './language.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';
import { LocalStorageService } from './local-storage.service';
import { GeneralService } from './general.service';
import { PlayerProfileService } from 'src/app/services/player-profile.service';
import { RegistrationService } from './registration.service';
import { GamesService } from './games.service';

@Injectable()
export class AppResolver implements Resolve<any>  {
    public pageArray: any = [];
    constructor(
        public _appService: AppService,
        public _dataService: DataService,
        public router: Router,
        public _routingService: RoutingService,
        public _configService: ConfigService,
        private transferState: TransferState,
        private _languageService: LanguageService,
        @Inject(PLATFORM_ID) private platformId: Object,
        // @Optional() @Inject('serverResponse') public apiData: any,
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        let pages = route.data.pages as Array<string>;
        let identifier = route.data.identifier as Array<string>;
        // console.log('pages>>>>', pages);

        pages = pages.concat(['headerPage', 'footerPage', 'infoModalPage', 'registerPage', 'userProfileIdVerificationPage', 'userProfileNotificationsPage', 'userProfileWithdrawPage', 'userProfileDepositPage']);

        return new Promise<any>((resolve, reject) => {
            const notContentAvailablePages = pages.filter((val) => {
                return !this.pageArray.includes(val);
            });

            let res = this._dataService.getContentApiIsData();
            if (!notContentAvailablePages.length && res.message) {
                this._dataService.loadInitApiData(res.message);
                this._configService.subscriberLoader(false);
                resolve(res);
            } else {
                this.pageArray.push(...pages);
                // console.log('notContentAvailablePages>>>>', notContentAvailablePages);
                if (!notContentAvailablePages.length) {
                    return resolve(res);
                }
                let params = {
                    'identifier': identifier || [],
                    'languageCode': this._languageService.getSelectedLanguage(),
                    'content': notContentAvailablePages
                };
                // console.log('params>>', params);
                this._appService.contentAPI(params).subscribe((res: any) => {
                    this._dataService.setContentApiIsData(res);
                    this._dataService.loadInitApiData(res.message);
                    this._configService.subscriberLoader(false);
                    // console.log('Server-side rendering inside api call>>>');
                    resolve(res);
                }, (err: any) => {
                    console.log('err>>', err);
                    reject(err);
                });
            }
        });

    }
}


@Injectable()
export class checkRootRoutingResolver {
    constructor(
        public _configService: ConfigService,
        public router: Router,
        public _languageService: LanguageService,
        public _routingService: RoutingService
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let url = state.url;
        const winUrl = window.location.href;
        let clientDefaultLang = this._languageService.getSelectedLanguage();

        try {
            if ((url.indexOf(clientDefaultLang) == -1)) {
                let qparams = route.queryParams || {};
                if (url.indexOf('?.ce') >= 0) {
                    this._routingService.altRouterLink('home', qparams);
                } else {
                    this._routingService.altRouterLink('home');
                }
            }
        } catch (e) {
            console.log('checkRootRoutingResolver data called');
        }
    }
}

@Injectable()
export class DefaultClientLangResolver {
    constructor(
        public _configService: ConfigService,
        public router: Router,
        public _languageService: LanguageService,
        public _routingService: RoutingService
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let url = state.url;
        const winUrl = window.location.href;
        let clientDefaultLang = this._languageService.getSelectedLanguage();

        try {
            if (url.includes('alt/') || url.includes('deposit-success') || url.includes('deposit-failure') || url.includes('deposit-pending') || url.includes('deposit-cancelled')) {
                return;
            }

            if (winUrl.includes('de/deposit')) {
                parent.postMessage('openDeposit', '*');
                return;
            }

            if ((url.indexOf(clientDefaultLang) == -1)) {
                let qparams = route.queryParams || {};
                if (url.indexOf('?.ce') >= 0) {
                    this._routingService.altRouterLink('home', qparams);
                } else {
                    this._routingService.altRouterLink('404');
                }
            }
        } catch (e) {
            console.log('DefaultClientLangResolver data called');
        }
    }
}

@Injectable()
export class SiteBlockResolver {
    constructor(
        public _userService: UserService,
        public _configService: ConfigService,
        public _generalService: GeneralService,
        public _localStorageService: LocalStorageService,
        public router: Router,
        public _routingService: RoutingService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        try {
            let isSiteBlocked = this._userService.getIsSiteBlocked();
            if (isSiteBlocked) {
                this._routingService.altRouterLink('home');
                this._generalService.infomodel('Site Restriction', 'Site is not allowed to access from this country.', 'Ok');
                return false;
            }
            return true;
        } catch (e) {
            console.log('SiteBlockResolver data called');
        }
    }
}

@Injectable()
export class AppLoginResolver implements Resolve<any> {
    constructor(
        public _userService: UserService,
        public router: Router,
        public _routingService: RoutingService
    ) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {

        try {
            let isLoggedIn = this._userService.checkLoginUser();
            if (!isLoggedIn) {
                this._routingService.altRouterLink('login', { returnUrl: state.url });
            }
        } catch (e) {
            console.log('AppLoginResolver data called');
        }
    }
}

@Injectable()
export class CheckPlayerLimitSettingResolver {
    constructor(
        public userService: UserService,
        public configService: ConfigService,
        public localStorageService: LocalStorageService,
        private routingService: RoutingService,
        public _playerProfileService: PlayerProfileService,
        private _registrationService: RegistrationService,
        public router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        try {
            return new Promise<any>((resolve, reject) => {
                const userDetail: any = this.localStorageService.get('player');
                if (!userDetail) {
                    return resolve(true);
                }

                this._playerProfileService.checkPlayerLimitSetting().then((res: any) => {
                    if (res === true) {
                        resolve(true);
                    } else {
                        this.routingService.altRouterLink('home');
                        this._registrationService.openLugasLimitModal();
                        resolve(false);
                    }
                }, (error: any) => {
                    reject(false);
                })
            });
        } catch (e) {
            console.log('CheckPlayerLimitSetting data called');
        }
    }
}

@Injectable()
export class AdyenGuard {
    constructor(
        public _userService: UserService,
        public _configService: ConfigService,
        public _generalService: GeneralService,
        private _routingService: RoutingService,
        public router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        try {
            let paymentMethod = this._configService.getAllowedPaymentMethod();
            let ret = false;
            for (let i in paymentMethod) {
                if (paymentMethod[i] == 'ADYEN') {
                    ret = true;
                }
            }

            if (this._generalService.checkConfirmedEmail()) {
                this._routingService.altRouterLink('home');
                ret = false;
            }
            return ret;
        } catch (e) {
            console.log('ADYENResolver data called');
        }
    }
}

@Injectable()
export class AppDepositGuard {
    constructor(
        private router: Router,
        private adyenGuard: AdyenGuard,
        private _routingService: RoutingService,
        private _configService: ConfigService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let canGo = [{
            guard: this.adyenGuard,
            url: this._routingService.altRouterLinkActive('adyen'),
        }].some((config): any => {
            let paymentMethod = this._configService.getAllowedPaymentMethod();
            if (paymentMethod.length > 1) {
                return true;
            }

            if (config.guard.canActivate(route, state)) {
                if (state.url !== `/${config.url}`) {
                    this.router.navigate([state.url + '/' + config.url]);
                }
                return true;
            }
        });
        return canGo;
    }
}

@Injectable()
export class DepositAllowedGuard {
    constructor(
        public _userService: UserService,
        public _configService: ConfigService,
        public _localStorageService: LocalStorageService,
        private _routingService: RoutingService,
        public router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        try {
            let userDetail: any = this._localStorageService.get('player');
            if (userDetail) {
                if (!userDetail.depositAllowed) {
                    this._routingService.altRouterLink('user/deposit');
                    return false;
                }
            }
            return true;
        } catch (e) {
            console.log('DepositAllowedGuard data called');
        }
    }
}

@Injectable()
export class WithdrawAllowedGuard {
    constructor(
        public _userService: UserService,
        public _configService: ConfigService,
        public _localStorageService: LocalStorageService,
        private _routingService: RoutingService,
        public router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        try {
            let userDetail: any = this._localStorageService.get('player');
            if (userDetail) {
                if (!userDetail.withdrawalAllowed) {
                    this._routingService.altRouterLink('user/payout');
                    return false;
                }
            }
            return true;
        } catch (e) {
            console.log('WithdrawAllowedGuard data called');
        }
    }
}

@Injectable()
export class DuplicateTabResolver {
    constructor(
        public _userService: UserService,
        public router: Router,
        public _generalService: GeneralService,
        public _routingService: RoutingService,
        public _gamesService: GamesService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        try {
            if (this._generalService.getBrowserTabId()) {
                this._routingService.altRouterLink('home');
                return false;
            }
            return true;
        } catch (e) {
            console.log('DuplicateTabResolver data called');
        }
    }
}


@Injectable()
export class CheckGamesIsAvailableResolver {
    constructor(
        public router: Router,
        private localStorageService: LocalStorageService,
        private routingService: RoutingService,
        private _playerProfileService: PlayerProfileService,
        private _registrationService: RegistrationService,
        private _gamesService: GamesService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        try {
            return new Promise<any>((resolve, reject) => {
                // const gameData = this.localStorageService.get('gamePlayData');
                let gamesList = this._gamesService.getGamesApiData();
                if (!gamesList) {
                    this._gamesService.loadGamesApi(['all']).then((res: any) => {
                        resolve(true);
                    }, (err: any) => {
                        resolve(true);
                    });
                } else {
                    resolve(true);
                }
            });
        } catch (e) {
            console.log('CheckGamesIsAvailableResolver data called');
        }
    }
}



